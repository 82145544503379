import https from "../https"

const games = {
  async checkMember(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/check-member`, { params });
  },
  async sendCoupon(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/send-coupon`, params);
  },
  async getCouponList(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/coupon-list`, { params });
  },
  async registerCustomer(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/register`, params);
  },
  async registerMember(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/register-and-member`, params);
  },
  async checkIsBindStore(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/check-store`, { params });
  },
  async bindStore(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/bind-store`, params);
  },
  async checkStoreCode(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/check-store-code`, params );
  },
  async redeemCoupon(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/redeem-coupon`, params );
  },
  async getCouponDetail(orgCode, params) {
    return https.get(`${orgCode}/liff/abbott-and/get-coupon`, { params });
  },
  async getEventStoreList(orgCode) {
    return https.get(`${orgCode}/liff/abbott-and/event-store-list`);
  },
  // Loyalty
  async getLoyaltyList(orgCode) {
    return https.get(`${orgCode}/liff/abbott-and/loyalty/list`);
  },
  async getLoyaltyDetail(orgCode, id) {
    return https.get(`${orgCode}/liff/abbott-and/loyalty/detail?loyalty_id=${id}`);
  },
  async redeemLoyalty(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/loyalty/redeem`, params);
  },
  async checkIsSendable(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/check-is-sendable`, params );
  },
  async joinChannel(orgCode, params) {
    return https.post(`${orgCode}/liff/abbott-and/join-channel`, params );
  },
}

export default games
