<template>
  <SharedButton
    :variant="`${buttonStyle}`"
    @click="handleClick"
    :disabled="disableButton"
  >
    {{ buttonText }}
  </SharedButton>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import liff from "@line/liff";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";

export default {
  mixins: [moduleMap],
  components: { SharedButton },
  props: {
    buttonStyle: {
      type: String,
      default: "s-btn-bg-primary",
    },
    buttonText: {
      type: String,
      default: "確認",
    },
    type: {
      type: String,
      default: "CLOSE",
    },
    buttonUrl: {
      type: String,
    },
    disableDefaultClick: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
    useCallback: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (this.useCallback) {
        this.$emit('custom-click', this.customClickCallback);
      } else {
        this.$emit('custom-click')
        this.customClickCallback();
      }
    },
    customClickCallback(error) {
      if (error) {
        console.error('Failed to handle custom click:', error);
        return;
      }
      if (this.disableDefaultClick) {
        return;
      }
      this.goNext();
    },
    goNext() {

      let m = this.moduleMap.find(m => m.type == this.type);

      if (m) {
        this.$router.push({ name: m.value, query: this.$route.query });
        return;
      }

      switch (this.type) {
        case "OA":
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
          break;
        case "URL":
          window.location.href = this.buttonUrl;
          break;
        case "proj.abbott_and.branch_picker":
          this.$router.push({ name: "LiffProjAbbottAndBranchPicker", query: this.$route.query });
          break;
        case "BACK":
          this.$router.go(-1);
          break;
        // case "member-center":
        //   this.$router.push({ name: "LiffMemberCenterHome", query: this.$route.query });
        //   break;
        // case "member-edit":
        //   this.$router.push({ name: "LiffMemberCenterEdit", query: this.$route.query });
        //   break;
        // case "edit_done":
        //   this.$router.push({ name: "LiffMemberCenterEditDone", query: this.$route.query });
        //   break;
        // case "welcome":
        //   this.$router.push({ name: "LiffRegisterWelcome", query: this.$route.query });
        //   break;
        // case "thanks":
        //   this.$router.push({ name: "LiffRegisterThanks", query: this.$route.query });
        //   break;
        // case "intro":
        //   this.$router.push({ name: "LiffRegisterIntro", query: this.$route.query });
        //   break;
        // case "register":
        //   this.$router.push({ name: "LiffRegisterIndex", query: this.$route.query });
        //   break;
        // case "staff.list":
        //   this.$router.push({ name: "LiffStaffList", query: this.$route.query });
        //   break;
        // case "staff.bind_success":
        //   this.$router.push({ name: "LiffStaffBindSuccess", query: this.$route.query });
        //   break;
        case "CLOSE":
        default:
           // 只在 liff app 有用
           liff.closeWindow();


      }
    }
  },
};
</script>
