<template>
  <button
    class="s-btn s-btn-xs"
    type="button"
    :class="`${variant}`"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "full",
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
button {
  font-size: 16px;
  background: var(--s-primary);
  color: #fff;

  &.s-btn-bg-primary {
    background: var(--liff-button-color);
    color: var(--liff-button-text-color);
  }

  &.s-btn-outline-primary {
    border: 1px solid var(--liff-button-color);
    color: var(--liff-button-color);
    background: var(--liff-button-text-color);
  }

  &:disabled {
    border: 1px solid #c0c4cc;
    background: #e5e5ea;
    color: #2c2c2e;
    opacity: 1;
    cursor: not-allowed;
  }
}
</style>
